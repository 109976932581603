import { find, flow, identity, includes, isEmpty, isNull, negate, over, property, size, some } from 'lodash/fp'

import { buildLens, falsifyUndefined, hasItems, isDefined, isNotNull, lensFactory } from '../../helpers'
import RootState from '../../state'
import { Capability, GetLens, Lens, Nullable, Optional, Transform, Validation } from '../../types/core'
import { isCollectorMetaPresent, isCustomerMetaPresent, isJunkLoverMetaPresent } from './helpers'
import State from './state'
import { AuthIdentifier, Meta } from './types'

export const metaRootLens: GetLens<RootState, State> = state => () => state.meta

const lens = lensFactory<State>()

export const metaDataLens = lens('data')
export const getMetaDataLens = buildLens(metaRootLens, metaDataLens)

export const getCapabilities = flow(getMetaDataLens, data => (isNotNull(data) ? data.capabilities : []))

export const getUserActions = flow(getMetaDataLens, data => data?.actions)
export const getAddIdentifierUrl = flow(getUserActions, actions => actions?.addIdentifier?.url)
export const getTradeCustomerPortalUrl = flow(getUserActions, actions => actions?.tradeCustomerPortal?.url)
export const getUpdateVatStatusUrl = flow(getUserActions, actions => actions?.updateVatStatus?.url)
export const isTradeCustomer = flow(getTradeCustomerPortalUrl, isDefined)

export const hasNoDataCapability: Transform<Nullable<Meta>, boolean> = data =>
  isNull(data) || hasNoCapability(data.capabilities)
export const hasAnyDataCapability = negate(hasNoDataCapability)
export const hasDataCapability: Transform<Capability, Transform<Nullable<Meta>, boolean>> = capability => data =>
  isNotNull(data) && hasCapability(capability)(data.capabilities)
export const hasDataSupplierCapability: Validation<Nullable<Meta>> = flow(
  over([hasDataCapability('collector'), hasDataCapability('junklover')]),
  some(identity),
)
export const hasNoCapability: Validation<Capability[]> = isEmpty
export const hasCapability: Transform<Capability, Validation<Capability[]>> = includes
export const hasCustomerCapability = hasCapability('customer')
export const hasCollectorCapability = hasCapability('collector')
export const hasJunkLoverCapability = hasCapability('junklover')
export const hasTipperCapability = hasCapability('tipper')
export const hasSupplierCapability: Validation<Capability[]> = flow(
  over([hasCollectorCapability, hasJunkLoverCapability]),
  some(identity),
)
export const hasSoleCapability = (capability: Capability) => (capabilities: Capability[]) =>
  size(capabilities) === 1 && hasCapability(capability)(capabilities)

export const hasSoleCustomerCapability = hasSoleCapability('customer')

export const messagingInfo = flow(getMetaDataLens, meta => meta?.messagingInfo)

export const hasUnreadMessagesLens: Lens<State, boolean> = ({ data, ...state }) => ({
  get: () => Boolean(data?.messagingInfo.hasUnreadMessages),
  set: hasUnreadMessages => ({
    ...state,
    data: data && { ...data, messagingInfo: { ...data.messagingInfo, hasUnreadMessages } },
  }),
})

export const hasGlobalUnreadMessagesLens: Lens<State, boolean> = ({ data, ...state }) => ({
  get: () => Boolean(data?.messagingInfo.hasGlobalUnreadMessages),
  set: hasGlobalUnreadMessages => ({
    ...state,
    data: data && { ...data, messagingInfo: { ...data.messagingInfo, hasGlobalUnreadMessages } },
  }),
})

export const hasUnreadMessages = buildLens(metaRootLens, hasUnreadMessagesLens)
export const hasGlobalUnreadMessages = buildLens(metaRootLens, hasGlobalUnreadMessagesLens)

export const reloadHasUnreadMessagesUrl = flow(messagingInfo, property('reloadHasUnreadMessagesUrl'))
export const reloadHasGlobalUnreadMessagesUrl = flow(messagingInfo, property('reloadHasGlobalUnreadMessagesUrl'))

export const hasRole = flow(hasCapability, check => flow(getCapabilities, check))
export const hasAnyRole = flow(getCapabilities, capabilities => !isEmpty(capabilities))
export const isCustomer = hasRole('customer')
export const isCollector = hasRole('collector')
export const isJunkLover = hasRole('junklover')
export const isTipper = hasRole('tipper')
export const isSupplier: Validation<RootState> = rootState => isCollector(rootState) || isJunkLover(rootState)

export const hasPaymentMethod = flow(getMetaDataLens, data =>
  isCustomerMetaPresent(data) ? hasItems(data.customerPaymentCapabilities) : undefined,
)

export const isCollectorApproved: Validation<Nullable<Meta>> = meta =>
  isCollectorMetaPresent(meta) && meta.collectorInfo.verificationStatus === 'approved'

export const isJunkReuserApproved: Validation<Nullable<Meta>> = meta =>
  isJunkLoverMetaPresent(meta) && meta.junkLoverInfo.status === 'active'

export const isApprovedCollector = flow(getMetaDataLens, isCollectorApproved)
export const isApprovedJunkReuser = flow(getMetaDataLens, isJunkReuserApproved)

export const favouriteCollectorsUrlLens: Transform<Nullable<Meta>, Optional<string>> = meta =>
  (isCustomerMetaPresent(meta) ? meta.customerInfo : undefined)?.favouriteCollectorsUrl

export const customerCardInfoLens = lens('customerCardInfo')
export const tipperCardInfoLens = lens('tipperCardInfo')
export const deleteAccountIntentLens = lens('deleteAccountIntent')
export const deleteCollectorAccountIntentLens = lens('deleteCollectorAccountIntent')
export const isDeletingAccountLens = lens('isDeletingAccount')
export const accountDeletionErrorLens = lens('accountDeletionError')

export const findVerifiedPhoneNumber: Transform<Meta, Optional<AuthIdentifier>> = ({ identifiers }) =>
  find<AuthIdentifier>({ type: 'phone', verified: true })(identifiers)

export const hasVerifiedPhoneNumberFromMeta: Validation<Nullable<Meta>> = flow(
  data => (data ? findVerifiedPhoneNumber(data) : undefined),
  falsifyUndefined,
)

export const hasVerifiedPhoneNumber = flow(getMetaDataLens, hasVerifiedPhoneNumberFromMeta)

export const phoneNumberLens = flow(
  getMetaDataLens,
  data => (data && findVerifiedPhoneNumber(data)?.identifier) || undefined,
)

export default metaRootLens
