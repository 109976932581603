import React, { useCallback, useEffect, useMemo } from 'react'

import { isCustomerMetaPresent } from '../../entities/meta/helpers'
import { customerCardInfoLens, metaDataLens, tipperCardInfoLens } from '../../entities/meta/lens'
import MetaState from '../../entities/meta/state'
import { CardInfo } from '../../entities/meta/types'
import { compactAndJoin, isNotNull } from '../../helpers'
import { Dispatch, NoOp, Nullable, PaymentMethod } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'

interface DispatchProps {
  fetchCardInfo: NoOp
  updatePreferredPaymentMethod: Dispatch<PaymentMethod>
}

interface OwnProps {
  onCardPress: NoOp

  nativePayText?: string
  showNative?: boolean
}

interface StateProps {
  cardInfo: Nullable<CardInfo>
  paymentCapabilities?: PaymentMethod[]
  preferredPaymentMethod?: PaymentMethod
}

export type ExternalProps = DispatchProps & StateProps

type Props = ExternalProps & OwnProps

interface CardButtonProps {
  onPress: NoOp
}

interface CheckBoxProps {
  onChange: Dispatch<boolean>

  value?: boolean
}

interface ElementsProps {
  CardButton: CardButtonProps
  CardIcon: unknown
  CheckBox: CheckBoxProps
  Container: unknown
  Icon: unknown
  IconAndTitle: unknown
  NativePayIcon: unknown
  Root: unknown
  Text: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> =
  ({ CardButton, CardIcon, CheckBox, Container, Icon, IconAndTitle, NativePayIcon, Root, Text }) =>
  ({
    cardInfo,
    paymentCapabilities,
    fetchCardInfo,
    nativePayText,
    onCardPress,
    preferredPaymentMethod,
    showNative = true,
    updatePreferredPaymentMethod,
  }) => {
    useEffect(() => {
      fetchCardInfo()
    }, [fetchCardInfo])

    const onCardSelect = useCallback(() => updatePreferredPaymentMethod('card'), [updatePreferredPaymentMethod])
    const onNativePaySelect = useCallback(() => updatePreferredPaymentMethod('native'), [updatePreferredPaymentMethod])
    const hasNative = paymentCapabilities?.includes('native') || false
    const hasCard = paymentCapabilities?.includes('card') || false
    const isNativePaySelected = preferredPaymentMethod === 'native'
    const isCardSelected = preferredPaymentMethod === 'card'
    const [brand, last4] = isNotNull(cardInfo) ? [cardInfo.brand, cardInfo.last4] : ['', '']
    const cardText = hasCard
      ? compactAndJoin(' ')([`**** **** **** ${last4}`, brand ? `(${brand.toUpperCase()})` : null, '(click to update)'])
      : 'Add payment card'
    const isNativeEnabled = useMemo(() => hasNative && showNative, [hasNative, showNative])

    return (
      <Root>
        {isNativeEnabled ? (
          <Container>
            <IconAndTitle>
              <Icon>
                <NativePayIcon />
              </Icon>
              <Text>{nativePayText}</Text>
            </IconAndTitle>
            {hasCard ? <CheckBox onChange={onNativePaySelect} value={isNativePaySelected} /> : null}
          </Container>
        ) : null}
        <Container>
          <CardButton onPress={onCardPress}>
            <IconAndTitle>
              <Icon>
                <CardIcon />
              </Icon>
              <Text>{cardText}</Text>
            </IconAndTitle>
          </CardButton>
          {hasCard && isNativeEnabled ? <CheckBox onChange={onCardSelect} value={isCardSelected} /> : null}
        </Container>
      </Root>
    )
  }

export function customerPaymentMethodMetaStateToStateProps(state: MetaState): StateProps {
  const baseState: StateProps = {
    cardInfo: customerCardInfoLens(state).get(),
    paymentCapabilities: undefined,
    preferredPaymentMethod: undefined,
  }

  const meta = metaDataLens(state).get()

  if (isCustomerMetaPresent(meta)) {
    baseState.paymentCapabilities = meta.customerPaymentCapabilities
    baseState.preferredPaymentMethod = meta.customerInfo.preferredPaymentMethod
  }

  return baseState
}

export function tipperPaymentMethodMetaStateToStateProps(state: MetaState): StateProps {
  const cardInfo = tipperCardInfoLens(state).get()

  const baseState: StateProps = {
    cardInfo,
    // We don't allow tippers to use native pay (yet)
    paymentCapabilities: cardInfo ? ['card'] : undefined,
    preferredPaymentMethod: 'card',
  }

  // Uncomment once we allow tippers to have native payment
  // const meta = metaDataLens(state).get()

  // if (isTipperMetaPresent(meta)) {
  // baseState.paymentCapabilities = meta.tipperPaymentCapabilities
  // baseState.preferredPaymentMethod = meta.tipperInfo.preferredPaymentMethod
  // }

  return baseState
}

export default factory
