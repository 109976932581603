import { isNull } from 'lodash/fp'

import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { isNotNull, localLensGetter, toLocalState, undefineNull } from '../../helpers'
import { ActionFactory, FailureActionFactory, Nullable, PayloadActionFactory } from '../../types/core'
import { PayloadThunk } from '../../types/thunk'
import { withEndpoints } from '../app/helpers'
import { Endpoints } from '../app/types'
import { fetchMetaStep } from '../meta/actions'
import { isCustomerMeta } from '../meta/helpers'
import { getMetaDataLens } from '../meta/lens'
import { Meta } from '../meta/types'
import signupCustomerRootLens, { isCustomerVatRegisteredLens } from '../signup-customer/lens'
import {
  createCustomerApi,
  createPaymentMethodToken,
  createSetupIntentApi,
  createStripeCustomerApi,
  updateStripeCustomerApi,
} from './api'
import {
  CardSetupPayload,
  ConfirmSetupIntentApi,
  ConfirmSetupIntentParams,
  ConfirmSetupIntentResponse,
  CreateCustomerParams,
  CreateCustomerResponseBody,
  CreateElmerCustomerResponseBody,
  CreatePaymentMethodTokenParams,
  CreatePaymentMethodTokenResponse,
  CreateSetupIntentParams,
  CreateSetupIntentResponseBody,
  UpdateStripeCustomerResponseBody,
} from './types'
import {
  AddPaymentThunkResult,
  CLEAR_ADD_CARD_ERROR,
  ClearAddCardError,
  COMPLETE_SETUP_PAYMENT_FLOW,
  CompleteSetupPaymentFlow,
  CONFIRM_CARD_FAILURE,
  CONFIRM_CARD_SUCCESS,
  CONFIRM_SETUP_INTENT_FAILURE,
  CONFIRM_SETUP_INTENT_START,
  CONFIRM_SETUP_INTENT_SUCCESS,
  ConfirmCardFailure,
  ConfirmCardSuccess,
  ConfirmSetupIntentFailure,
  ConfirmSetupIntentResult,
  ConfirmSetupIntentStart,
  ConfirmSetupIntentSuccess,
  CREATE_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_START,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_ELMER_CUSTOMER_FAILURE,
  CREATE_ELMER_CUSTOMER_START,
  CREATE_ELMER_CUSTOMER_SUCCESS,
  CREATE_PAYMENT_METHOD_TOKEN_FAILURE,
  CREATE_PAYMENT_METHOD_TOKEN_START,
  CREATE_PAYMENT_METHOD_TOKEN_SUCCESS,
  CREATE_SETUP_INTENT_FAILURE,
  CREATE_SETUP_INTENT_START,
  CREATE_SETUP_INTENT_SUCCESS,
  CreateCustomerFailure,
  CreateCustomerStart,
  CreateCustomerSuccess,
  CreateElmerCustomerFailure,
  CreateElmerCustomerStart,
  CreateElmerCustomerSuccess,
  CreatePaymentMethodTokenFailure,
  CreatePaymentMethodTokenResult,
  CreatePaymentMethodTokenStart,
  CreatePaymentMethodTokenSuccess,
  CreateSetupIntentFailure,
  CreateSetupIntentStart,
  CreateSetupIntentSuccess,
  CUSTOMER_CARD_SETUP_FAILURE,
  CUSTOMER_CARD_SETUP_START,
  CUSTOMER_CARD_SETUP_SUCCESS,
  CUSTOMER_CARD_UPDATE_FAILURE,
  CUSTOMER_CARD_UPDATE_START,
  CUSTOMER_CARD_UPDATE_SUCCESS,
  CustomerCardSetupFailure,
  CustomerCardSetupResult,
  CustomerCardSetupStart,
  CustomerCardSetupSuccess,
  CustomerCardUpdateFailure,
  CustomerCardUpdateStart,
  CustomerCardUpdateSuccess,
  INIT_SETUP_PAYMENT_FLOW,
  InitSetupPaymentFlow,
  SET_POST_SETUP,
  SetPostSetup,
  TIPPER_CARD_SETUP_FAILURE,
  TIPPER_CARD_SETUP_START,
  TIPPER_CARD_SETUP_SUCCESS,
  TIPPER_CARD_UPDATE_FAILURE,
  TIPPER_CARD_UPDATE_START,
  TIPPER_CARD_UPDATE_SUCCESS,
  TipperCardSetupFailure,
  TipperCardSetupStart,
  TipperCardSetupSuccess,
  TipperCardUpdateFailure,
  TipperCardUpdateStart,
  TipperCardUpdateSuccess,
  UPDATE_STRIPE_CUSTOMER_FAILURE,
  UPDATE_STRIPE_CUSTOMER_START,
  UPDATE_STRIPE_CUSTOMER_SUCCESS,
  UpdateStripeCustomerFailure,
  UpdateStripeCustomerStart,
  UpdateStripeCustomerSuccess,
} from './types/actions'

export const initSetupPaymentFlow: PayloadActionFactory<InitSetupPaymentFlow, InitSetupPaymentFlow['payload']> =
  payloadAction(INIT_SETUP_PAYMENT_FLOW)

export const completeSetupPaymentFlow: ActionFactory<CompleteSetupPaymentFlow> = action(COMPLETE_SETUP_PAYMENT_FLOW)

export const confirmSetupIntentStart: ActionFactory<ConfirmSetupIntentStart> = action(CONFIRM_SETUP_INTENT_START)
export const confirmSetupIntentSuccess: PayloadActionFactory<ConfirmSetupIntentSuccess, ConfirmSetupIntentResponse> =
  payloadAction(CONFIRM_SETUP_INTENT_SUCCESS)
export const confirmSetupIntentFailure: FailureActionFactory<ConfirmSetupIntentFailure> =
  failureAction(CONFIRM_SETUP_INTENT_FAILURE)

export const confirmStripeSetupIntent =
  (confirmSetupIntentApi: ConfirmSetupIntentApi): PayloadThunk<ConfirmSetupIntentParams, ConfirmSetupIntentResult> =>
  params =>
  dispatch => {
    dispatch(confirmSetupIntentStart())

    return confirmSetupIntentApi(params)
      .then(confirmSetupIntentSuccess)
      .catch(confirmSetupIntentFailure)
      .then(action => dispatch(action))
  }

const createElmerCustomerActionBundle: ActionBundle<
  CreateElmerCustomerStart,
  CreateElmerCustomerSuccess,
  CreateElmerCustomerFailure,
  CreateElmerCustomerResponseBody
> = [
  action(CREATE_ELMER_CUSTOMER_START),
  payloadAction(CREATE_ELMER_CUSTOMER_SUCCESS),
  failureAction(CREATE_ELMER_CUSTOMER_FAILURE),
]

export const createElmerCustomer = (customersEndpoint: string, params?: CreateCustomerParams) =>
  apiAction(createElmerCustomerActionBundle)(createCustomerApi(customersEndpoint, params))

export const createPaymentMethodTokenFailure: FailureActionFactory<CreatePaymentMethodTokenFailure> = failureAction(
  CREATE_PAYMENT_METHOD_TOKEN_FAILURE,
)
const createPaymentMethodTokenActionBundle: ActionBundle<
  CreatePaymentMethodTokenStart,
  CreatePaymentMethodTokenSuccess,
  CreatePaymentMethodTokenFailure,
  CreatePaymentMethodTokenResponse
> = [
  action(CREATE_PAYMENT_METHOD_TOKEN_START),
  payloadAction(CREATE_PAYMENT_METHOD_TOKEN_SUCCESS),
  createPaymentMethodTokenFailure,
]

export const createStripePaymentMethodToken = (paymentMethodsEndpoint: string, body: CreatePaymentMethodTokenParams) =>
  apiAction(createPaymentMethodTokenActionBundle)(createPaymentMethodToken(paymentMethodsEndpoint, body))

export const createCustomerStart: ActionFactory<CreateCustomerStart> = action(CREATE_CUSTOMER_START)
export const createCustomerSuccess: PayloadActionFactory<CreateCustomerSuccess, CreateCustomerResponseBody> =
  payloadAction(CREATE_CUSTOMER_SUCCESS)
export const createCustomerFailure: FailureActionFactory<CreateCustomerFailure> = failureAction(CREATE_CUSTOMER_FAILURE)
export const createCustomerActionBundle: ActionBundle<
  CreateCustomerStart,
  CreateCustomerSuccess,
  CreateCustomerFailure,
  CreateCustomerResponseBody
> = [createCustomerStart, createCustomerSuccess, createCustomerFailure]

export const createStripeCustomer = (stripeCustomersEndpoint: string, update: boolean) =>
  apiAction(createCustomerActionBundle)(createStripeCustomerApi(stripeCustomersEndpoint, update))

export const createSetupIntentStart: ActionFactory<CreateSetupIntentStart> = action(CREATE_SETUP_INTENT_START)
export const createSetupIntentSuccess: PayloadActionFactory<CreateSetupIntentSuccess, CreateSetupIntentResponseBody> =
  payloadAction(CREATE_SETUP_INTENT_SUCCESS)
export const createSetupIntentFailure: FailureActionFactory<CreateSetupIntentFailure> =
  failureAction(CREATE_SETUP_INTENT_FAILURE)

export const createSetupIntentActionBundle: ActionBundle<
  CreateSetupIntentStart,
  CreateSetupIntentSuccess,
  CreateSetupIntentFailure,
  CreateSetupIntentResponseBody
> = [createSetupIntentStart, createSetupIntentSuccess, createSetupIntentFailure]

export const createStripeSetupIntent = (setupIntentsEndpoint: string, params: CreateSetupIntentParams) =>
  apiAction(createSetupIntentActionBundle)(createSetupIntentApi(setupIntentsEndpoint, params))

export const customerCardSetupStart: ActionFactory<CustomerCardSetupStart> = action(CUSTOMER_CARD_SETUP_START)
export const customerCardSetupSuccess: ActionFactory<CustomerCardSetupSuccess> = action(CUSTOMER_CARD_SETUP_SUCCESS)
export const customerCardSetupFailure: FailureActionFactory<CustomerCardSetupFailure> =
  failureAction(CUSTOMER_CARD_SETUP_FAILURE)

export const tipperCardSetupStart: ActionFactory<TipperCardSetupStart> = action(TIPPER_CARD_SETUP_START)
export const tipperCardSetupSuccess: ActionFactory<TipperCardSetupSuccess> = action(TIPPER_CARD_SETUP_SUCCESS)
export const tipperCardSetupFailure: FailureActionFactory<TipperCardSetupFailure> =
  failureAction(TIPPER_CARD_SETUP_FAILURE)

interface CustomerCardSetupStrategy {
  paymentMethodsUrl: string
  stripeCustomersUrl: string
  isNewCustomer: boolean
}

const customerCardSetupStrategy = (
  meta: Nullable<Meta>,
  stripeEndpoints: Endpoints['stripe'],
): CustomerCardSetupStrategy => {
  if (isNull(meta) || !isCustomerMeta(meta)) {
    return {
      isNewCustomer: true,
      paymentMethodsUrl: stripeEndpoints.paymentMethods,
      stripeCustomersUrl: stripeEndpoints.customers,
    }
  } else {
    const {
      customerStripeInfo: { paymentMethodsUrl, stripeCustomerUrl: stripeCustomersUrl },
    } = meta

    return {
      isNewCustomer: false,
      paymentMethodsUrl,
      stripeCustomersUrl,
    }
  }
}

const customerCardSetup: PayloadThunk<CardSetupPayload, CustomerCardSetupResult> = ({
  confirmSetupIntentApi,
  paymentMethod,
}) =>
  withEndpoints(
    ({ customers: elmerCustomersUrl, stripe }) =>
      (dispatch, getState) => {
        dispatch(customerCardSetupStart())

        const rootState = getState()
        const meta = getMetaDataLens(rootState)
        const isVatRegistered = undefineNull(
          localLensGetter(isCustomerVatRegisteredLens)(toLocalState(signupCustomerRootLens)(rootState)),
        )
        const { paymentMethodsUrl, stripeCustomersUrl, isNewCustomer } = customerCardSetupStrategy(meta, stripe)

        return dispatch(createStripeCustomer(stripeCustomersUrl, !isNewCustomer)).then(action => {
          if (action.type === CREATE_CUSTOMER_SUCCESS) {
            const { serverSecret, stripeCustomerId } = action.payload

            return dispatch(createStripeSetupIntent(stripe.setupIntents, action.payload))
              .then<ConfirmSetupIntentResult>(action => {
                if (action.type === CREATE_SETUP_INTENT_SUCCESS) {
                  const { clientSecret } = action.payload

                  return dispatch(confirmStripeSetupIntent(confirmSetupIntentApi)({ clientSecret, paymentMethod }))
                }

                return dispatch(confirmSetupIntentFailure(new Error('failed due to setup intent failure')))
              })
              .then<CreatePaymentMethodTokenResult>(action => {
                if (action.type === CONFIRM_SETUP_INTENT_SUCCESS) {
                  const { paymentMethodToken, status } = action.payload

                  if (status === 'succeeded' && isNotNull(paymentMethodToken)) {
                    return dispatch(
                      createStripePaymentMethodToken(paymentMethodsUrl, { paymentMethodToken, serverSecret }),
                    )
                  }
                }

                return dispatch(
                  createPaymentMethodTokenFailure(new Error('failed due to confirm setup intent failure')),
                )
              })
              .then<CustomerCardSetupResult>(action =>
                action.type === CREATE_PAYMENT_METHOD_TOKEN_SUCCESS
                  ? isNewCustomer
                    ? dispatch(
                        createElmerCustomer(elmerCustomersUrl, {
                          isVatRegistered,
                          stripeCustomerId,
                          preferredPaymentMethod: 'card',
                        }),
                      ).then(action =>
                        dispatch(
                          action.type === CREATE_ELMER_CUSTOMER_SUCCESS
                            ? customerCardSetupSuccess()
                            : customerCardSetupFailure(action.payload),
                        ),
                      )
                    : dispatch(customerCardSetupSuccess())
                  : dispatch(customerCardSetupFailure(action.payload)),
              )
          }

          return dispatch(customerCardSetupFailure(action.payload))
        })
      },
    customerCardSetupFailure,
  )

export const addCard: PayloadThunk<CardSetupPayload, AddPaymentThunkResult> = payload => dispatch => {
  dispatch(initSetupPaymentFlow({ confirmPayment: false }))

  return dispatch(customerCardSetup(payload))
    .then(fetchMetaStep(CUSTOMER_CARD_SETUP_SUCCESS))
    .then(action => dispatch(action))
    .then(action => {
      dispatch(completeSetupPaymentFlow())

      return action
    })
}

export const clearAddCardError: ActionFactory<ClearAddCardError> = action(CLEAR_ADD_CARD_ERROR)

const updateStripeCustomerActionBundle: ActionBundle<
  UpdateStripeCustomerStart,
  UpdateStripeCustomerSuccess,
  UpdateStripeCustomerFailure,
  UpdateStripeCustomerResponseBody
> = [
  action(UPDATE_STRIPE_CUSTOMER_START),
  payloadAction(UPDATE_STRIPE_CUSTOMER_SUCCESS),
  failureAction(UPDATE_STRIPE_CUSTOMER_FAILURE),
]

export const updateStripeCustomerAndGetSetupIntent = (stripeCustomersEndpoint: string) =>
  apiAction(updateStripeCustomerActionBundle)(updateStripeCustomerApi(stripeCustomersEndpoint))

export const customerCardUpdateStart: ActionFactory<CustomerCardUpdateStart> = action(CUSTOMER_CARD_UPDATE_START)
export const customerCardUpdateSuccess: PayloadActionFactory<
  CustomerCardUpdateSuccess,
  CreatePaymentMethodTokenResponse
> = payloadAction(CUSTOMER_CARD_UPDATE_SUCCESS)
export const customerCardUpdateFailure: FailureActionFactory<CustomerCardUpdateFailure> =
  failureAction(CUSTOMER_CARD_UPDATE_FAILURE)

export const tipperCardUpdateStart: ActionFactory<TipperCardUpdateStart> = action(TIPPER_CARD_UPDATE_START)
export const tipperCardUpdateSuccess: PayloadActionFactory<TipperCardUpdateSuccess, CreatePaymentMethodTokenResponse> =
  payloadAction(TIPPER_CARD_UPDATE_SUCCESS)
export const tipperCardUpdateFailure: FailureActionFactory<TipperCardUpdateFailure> =
  failureAction(TIPPER_CARD_UPDATE_FAILURE)

export const setPostSetup: PayloadActionFactory<SetPostSetup, SetPostSetup['payload']> = payloadAction(SET_POST_SETUP)

export const confirmCardSuccess: ActionFactory<ConfirmCardSuccess> = action(CONFIRM_CARD_SUCCESS)
export const confirmCardFailure: FailureActionFactory<ConfirmCardFailure> = failureAction(CONFIRM_CARD_FAILURE)
