import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { MissingTipperViewSavedCardUrlError, NotCustomerError, NotTipperError } from '../../errors'
import { actionStep } from '../../helpers'
import { ActionFactory, FailureActionFactory, Nullable, PayloadActionFactory } from '../../types/core'
import { StatelessPayloadThunk, StatelessThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { isCustomerMetaPresent, isTipperMetaPresent } from '../meta/helpers'
import { deleteAccountApi, fetchCardInfo, getMetaApi, notificationsPreferenceApi, setVatRegisteredApi } from './api'
import {
  CardInfo,
  CONFIRM_ACCOUNT_DELETED,
  ConfirmAccountDeleted,
  CustomerCardInfoFailure,
  CustomerCardInfoResult,
  CustomerCardInfoStart,
  CustomerCardInfoSuccess,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  DeleteAccountFailure,
  DeleteAccountResult,
  DeleteAccountStart,
  DeleteAccountSuccess,
  GET_CUSTOMER_CARD_INFO_FAILURE,
  GET_CUSTOMER_CARD_INFO_START,
  GET_CUSTOMER_CARD_INFO_SUCCESS,
  GET_META_FAILURE,
  GET_META_START,
  GET_META_SUCCESS,
  GET_TIPPER_CARD_INFO_FAILURE,
  GET_TIPPER_CARD_INFO_START,
  GET_TIPPER_CARD_INFO_SUCCESS,
  GetMetaFailure,
  GetMetaResult,
  GetMetaStart,
  GetMetaSuccess,
  Meta,
  SET_DELETE_ACCOUNT_INTENT,
  SET_DELETE_COLLECTOR_ACCOUNT_INTENT,
  SET_VAT_REGISTERED_FAILURE,
  SET_VAT_REGISTERED_START,
  SET_VAT_REGISTERED_SUCCESS,
  SetDeleteAccountIntent,
  SetDeleteCollectorAccountIntent,
  SetVatRegisteredFailure,
  SetVatRegisteredResult,
  SetVatRegisteredStart,
  SetVatRegisteredSuccess,
  TipperCardInfoFailure,
  TipperCardInfoResult,
  TipperCardInfoStart,
  TipperCardInfoSuccess,
  UPDATE_NOTIFICATION_PREFERENCES_FAILURE,
  UPDATE_NOTIFICATION_PREFERENCES_START,
  UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UpdateNotificationPreferencesFailure,
  UpdateNotificationPreferencesResult,
  UpdateNotificationPreferencesStart,
  UpdateNotificationPreferencesSuccess,
  UpdateUserFailure,
  UpdateUserStart,
  UpdateUserSuccess,
} from './types'

export const metaFailure: FailureActionFactory<GetMetaFailure> = failureAction(GET_META_FAILURE)
export const metaActionBundle: ActionBundle<GetMetaStart, GetMetaSuccess, GetMetaFailure, Meta> = [
  action(GET_META_START),
  payloadAction(GET_META_SUCCESS),
  metaFailure,
]

export const setDeleteAccountIntent: PayloadActionFactory<SetDeleteAccountIntent, SetDeleteAccountIntent['payload']> =
  payloadAction(SET_DELETE_ACCOUNT_INTENT)

export const setDeleteCollectorAccountIntent: PayloadActionFactory<
  SetDeleteCollectorAccountIntent,
  SetDeleteCollectorAccountIntent['payload']
> = payloadAction(SET_DELETE_COLLECTOR_ACCOUNT_INTENT)

export const setVatRegisteredFailure: FailureActionFactory<SetVatRegisteredFailure> =
  failureAction(SET_VAT_REGISTERED_FAILURE)

export const setVatRegisteredResultBundle: ActionBundle<
  SetVatRegisteredStart,
  SetVatRegisteredSuccess,
  SetVatRegisteredFailure
> = [action(SET_VAT_REGISTERED_START), payloadAction(SET_VAT_REGISTERED_SUCCESS), setVatRegisteredFailure]

interface SetVatRegisteredPayload {
  isVatRegistered: boolean
  url: string
}

export const setVatRegistered: StatelessPayloadThunk<SetVatRegisteredPayload, SetVatRegisteredResult> =
  ({ isVatRegistered, url }) =>
  dispatch =>
    dispatch(apiAction(setVatRegisteredResultBundle)(setVatRegisteredApi(url, isVatRegistered)))
      .then(fetchMetaStep(SET_VAT_REGISTERED_SUCCESS))
      .then(action => dispatch(action))

export const fetchMeta: StatelessThunk<GetMetaResult> = () =>
  withEndpoint(
    'whoami',
    whoamiUrl => dispatch => dispatch(apiAction(metaActionBundle)(getMetaApi(whoamiUrl))),
    metaFailure,
  )

export const fetchMetaStep = actionStep(fetchMeta)

export interface UpdateNotificationPreferencesPayload {
  url: string
  receiveMarketingNotifications: boolean
}

const updateNotificationPreferencesActionBundle: ActionBundle<
  UpdateNotificationPreferencesStart,
  UpdateNotificationPreferencesSuccess,
  UpdateNotificationPreferencesFailure,
  boolean
> = [
  action(UPDATE_NOTIFICATION_PREFERENCES_START),
  payloadAction(UPDATE_NOTIFICATION_PREFERENCES_SUCCESS),
  failureAction(UPDATE_NOTIFICATION_PREFERENCES_FAILURE),
]

export const updateNotificationPreferences: StatelessPayloadThunk<
  UpdateNotificationPreferencesPayload,
  UpdateNotificationPreferencesResult
> =
  ({ url, receiveMarketingNotifications }) =>
  dispatch =>
    dispatch(
      apiAction(updateNotificationPreferencesActionBundle)(
        notificationsPreferenceApi(url, receiveMarketingNotifications),
      ),
    )
      .then(fetchMetaStep(UPDATE_NOTIFICATION_PREFERENCES_SUCCESS))
      .then(action => dispatch(action))

export const updateUserFailure: FailureActionFactory<UpdateUserFailure> = failureAction(UPDATE_USER_FAILURE)

export const updateUserActionBundle: ActionBundle<UpdateUserStart, UpdateUserSuccess, UpdateUserFailure> = [
  action(UPDATE_USER_START),
  action(UPDATE_USER_SUCCESS),
  updateUserFailure,
]

const customerStripeMetaFailure: FailureActionFactory<CustomerCardInfoFailure> =
  failureAction(GET_CUSTOMER_CARD_INFO_FAILURE)
const customerStripeMetaActionBundle: ActionBundle<
  CustomerCardInfoStart,
  CustomerCardInfoSuccess,
  CustomerCardInfoFailure,
  CardInfo
> = [action(GET_CUSTOMER_CARD_INFO_START), payloadAction(GET_CUSTOMER_CARD_INFO_SUCCESS), customerStripeMetaFailure]

export const customerCardInfo: StatelessPayloadThunk<Nullable<Meta>, CustomerCardInfoResult> = meta => dispatch =>
  isCustomerMetaPresent(meta)
    ? dispatch(apiAction(customerStripeMetaActionBundle)(fetchCardInfo(meta.customerStripeInfo.paymentMethodsUrl)))
    : Promise.resolve(dispatch(customerStripeMetaFailure(new NotCustomerError())))

const tipperStripeMetaFailure: FailureActionFactory<TipperCardInfoFailure> = failureAction(GET_TIPPER_CARD_INFO_FAILURE)
const tipperStripeMetaActionBundle: ActionBundle<
  TipperCardInfoStart,
  TipperCardInfoSuccess,
  TipperCardInfoFailure,
  CardInfo
> = [action(GET_TIPPER_CARD_INFO_START), payloadAction(GET_TIPPER_CARD_INFO_SUCCESS), tipperStripeMetaFailure]

export const tipperCardInfo: StatelessPayloadThunk<Nullable<Meta>, TipperCardInfoResult> = meta => dispatch => {
  if (!isTipperMetaPresent(meta)) {
    return Promise.resolve(dispatch(tipperStripeMetaFailure(new NotTipperError())))
  }

  const paymentMethodUrl = meta.actions.tipperStripeActions?.viewSavedCard.url

  if (paymentMethodUrl != null) {
    return dispatch(apiAction(tipperStripeMetaActionBundle)(fetchCardInfo(paymentMethodUrl)))
  }

  return Promise.resolve(dispatch(tipperStripeMetaFailure(new MissingTipperViewSavedCardUrlError())))
}

export const deleteAccountFailure: FailureActionFactory<DeleteAccountFailure> = failureAction(DELETE_ACCOUNT_FAILURE)

const deleteAccountActionBundle: ActionBundle<DeleteAccountStart, DeleteAccountSuccess, DeleteAccountFailure> = [
  action(DELETE_ACCOUNT_START),
  action(DELETE_ACCOUNT_SUCCESS),
  deleteAccountFailure,
]

export const deleteAccount: StatelessPayloadThunk<string, DeleteAccountResult> = url => dispatch =>
  dispatch(apiAction(deleteAccountActionBundle)(deleteAccountApi(url)))

export const confirmAccountDeleted: ActionFactory<ConfirmAccountDeleted> = action(CONFIRM_ACCOUNT_DELETED)
