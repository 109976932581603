import { RequestOptions } from './api/types'
import { AdvertId } from './entities/advert/types'
import { Capability } from './types/core'

export const isError =
  <T extends Error>(name: string) =>
  (e: Error): e is T =>
    e.name === name

export class BaseError extends Error {
  constructor(name: string, message?: string) {
    super(message)

    this.name = name
  }
}

const NetworkErrorName = 'NetworkError'

export class NetworkError extends BaseError {
  constructor(readonly url: string, readonly alert: boolean) {
    super(NetworkErrorName, url)
  }
}

const isNetworkError = isError<NetworkError>(NetworkErrorName)

const TimeoutErrorName = 'TimeoutError'

export class TimeoutError extends BaseError {
  constructor(readonly seconds: number, readonly url: string, readonly alert: boolean) {
    super(TimeoutErrorName, `${seconds}s | ${url}`)
  }
}

const isTimeoutError = isError<TimeoutError>(TimeoutErrorName)

type NetworkProblem = NetworkError | TimeoutError

export const isNetworkProblem = (error: Error): error is NetworkProblem =>
  isNetworkError(error) || isTimeoutError(error)

const OAuthTokenErrorName = 'OAuthTokenError'

export class OAuthTokenError extends BaseError {
  constructor(readonly opts: RequestOptions) {
    super(OAuthTokenErrorName)
  }
}

export const isOAuthTokenError = isError<OAuthTokenError>(OAuthTokenErrorName)

export class AdvertDetailsNotFoundError extends BaseError {
  constructor() {
    super('AdvertDetailsNotFound')
  }
}

const CollectorNotApprovedErrorName = 'CollectorNotApproved'

export class CollectorNotApprovedError extends BaseError {
  constructor() {
    super(CollectorNotApprovedErrorName)
  }
}

export const isCollectorNotApprovedError = isError<CollectorNotApprovedError>(CollectorNotApprovedErrorName)

const JunkLoverNotApprovedErrorName = 'JunkLoverNotApproved'

export class JunkLoverNotApprovedError extends BaseError {
  constructor() {
    super(JunkLoverNotApprovedErrorName)
  }
}

export const isJunkLoverNotApprovedError = isError<JunkLoverNotApprovedError>(JunkLoverNotApprovedErrorName)

export class InvalidTimeSlotError extends BaseError {
  constructor() {
    super('InvalidTimeSlot')
  }
}

export class PublishConfirmationError extends BaseError {
  constructor() {
    super('PublishConfirmationError', 'Publish confirmation failed after customer creation')
  }
}

export class NoAccessTokenError extends BaseError {
  constructor() {
    super('NoAccessToken')
  }
}

const NoSessionErrorName = 'NoSessionError'

export class NoSessionError extends BaseError {
  constructor() {
    super(NoSessionErrorName)
  }
}

export const isNoSessionError = isError<NoSessionError>(NoSessionErrorName)

const NotRoleErrorName = 'NotRole'

export class NotRoleError extends BaseError {
  constructor(capability: Capability) {
    super(NotRoleErrorName, 'user is not a ' + capability)
  }
}

export const isNotRoleError = isError<NotRoleError>(NotRoleErrorName)

export class NotCollectorError extends NotRoleError {
  constructor() {
    super('collector')
  }
}

export class NotCustomerError extends NotRoleError {
  constructor() {
    super('customer')
  }
}

export class NotTipperError extends NotRoleError {
  constructor() {
    super('tipper')
  }
}

export class NotJunkLoverError extends NotRoleError {
  constructor() {
    super('junklover')
  }
}

export class NotTradeCustomerError extends BaseError {
  constructor() {
    // Unfortunately, we don't have trade customer in capablities just yet
    super('NotRole', 'user is not a trade customer')
  }
}

export class NotImplementedError extends BaseError {
  constructor(readonly feature: string) {
    super('NotImplemented', feature)
  }
}

export class PostcodeNotSupportedError extends BaseError {
  constructor() {
    super('PostcodeNotSupported')
  }
}

const CollectionIntentNotConfirmedErrorName = 'CollectionIntentNotConfirmed'

export class CollectionIntentNotConfirmedError extends BaseError {
  constructor() {
    super(CollectionIntentNotConfirmedErrorName)
  }
}

export const isCollectionIntentNotConfirmedError = isError<CollectionIntentNotConfirmedError>(
  CollectionIntentNotConfirmedErrorName,
)

export class NoMetaError extends BaseError {
  constructor() {
    super('NoMeta')
  }
}

export class NoPriceError extends BaseError {
  constructor() {
    super('NoPrice')
  }
}

export class DeleteAccountError extends BaseError {
  constructor(message: string) {
    super('DeleteAccount', message)
  }
}

const NoActivityToReloadHasUnreadMessagesErrorName = 'NoActivityToReloadHasUnreadMessages'

export class NoActivityToReloadHasUnreadMessagesError extends BaseError {
  constructor(advertId: AdvertId) {
    super(
      NoActivityToReloadHasUnreadMessagesErrorName,
      `Cannot reload 'hasUnreadMessages'. No activity found matching advertId=${advertId} activity.`,
    )
  }
}

export const isNoActivityToReloadHasUnreadMessagesError = isError<NoActivityToReloadHasUnreadMessagesError>(
  NoActivityToReloadHasUnreadMessagesErrorName,
)

const NoReloadHasUnreadMessagesActionErrorName = 'NoReloadHasUnreadMessagesAction'

export class NoReloadHasUnreadMessagesActionError extends BaseError {
  constructor(advertId: AdvertId) {
    super(
      NoReloadHasUnreadMessagesActionErrorName,
      `Cannot reload 'hasUnreadMessages'. No 'reloadHasUnreadMessages' action present for advertId=${advertId} activity.`,
    )
  }
}

export const isNoReloadHasUnreadMessagesActionError = isError<NoReloadHasUnreadMessagesActionError>(
  NoReloadHasUnreadMessagesActionErrorName,
)

const UnsupportedPostcodeErrorName = 'UnsupportedPostcode'

export class UnsupportedPostcodeError extends BaseError {
  constructor(postcode: string) {
    super(UnsupportedPostcodeErrorName, `No geocoder result for postcode: ${postcode}`)
  }
}

export const isUnsupportedPostcodeError = isError<UnsupportedPostcodeError>(UnsupportedPostcodeErrorName)

export const REDIRECT_TO_ADD_CARD = 'paymentStrategyThunk resolved with "addCard"'
export const REDIRECT_TO_ASK = 'paymentStrategyThunk resolved with "ask"'

export class HazardousWasteError extends Error {
  constructor(readonly words: string[] = []) {
    super()
  }
}

const MissingQrCodeErrorName = 'MissingQrCodeError'

export class MissingQrCodeError extends BaseError {
  constructor() {
    super(MissingQrCodeErrorName)
  }
}

export const isMissingQrCodeError = isError<MissingQrCodeError>(MissingQrCodeErrorName)

const MissingPublicSitesUrlErrorName = 'MissingPublicSitesUrlError'

export class MissingPublicSitesUrlError extends BaseError {
  constructor(message?: string) {
    super(MissingPublicSitesUrlErrorName, message)
  }
}

export const isMissingPublicSitesUrlError = isError<MissingPublicSitesUrlError>(MissingPublicSitesUrlErrorName)

const MissingTipperViewSavedCardUrlErrorName = 'MissingTipperViewSavedCardUrlErrorName'

export class MissingTipperViewSavedCardUrlError extends BaseError {
  constructor(message?: string) {
    super(MissingTipperViewSavedCardUrlErrorName, message)
  }
}
const MissingTipperUpdateSavedCardUrlErrorName = 'MissingTipperUpdateSavedCardUrlErrorName'

export class MissingTipperUpdateSavedCardUrlError extends BaseError {
  constructor(message?: string) {
    super(MissingTipperUpdateSavedCardUrlErrorName, message)
  }
}

const MissingGetOrCreateStripeCustomerUrlErrorName = 'MissingGetOrCreateStripeCustomerUrlErrorName'

export class MissingGetOrCreateStripeCustomerUrlError extends BaseError {
  constructor(message?: string) {
    super(MissingGetOrCreateStripeCustomerUrlErrorName, message)
  }
}

const MissingDataUrlErrorName = 'MissingDataUrlErrorName'

export class MissingDataUrlError extends BaseError {
  constructor(message?: string) {
    super(MissingDataUrlErrorName, message)
  }
}

export const isMissingDataUrlErrorError = isError<MissingDataUrlError>(MissingDataUrlErrorName)
