import { Action } from 'redux'

import {
  AuthIdentifierType,
  AuthIdentifierValue,
  Capability,
  Currency,
  FailureAction,
  Nullable,
  PayloadAction,
  PaymentMethod,
  Price,
  RemoteUrlAction,
  Response,
} from '../../types/core'

export const GET_META_START = 'GET_META_START'
export const GET_META_SUCCESS = 'GET_META_SUCCESS'
export const GET_META_FAILURE = 'GET_META_FAILURE'

export type GetMetaStart = Action<typeof GET_META_START>
export type GetMetaSuccess = PayloadAction<typeof GET_META_SUCCESS, Meta>
export type GetMetaFailure = FailureAction<typeof GET_META_FAILURE>

export type GetMetaResult = GetMetaFailure | GetMetaSuccess
export type GetMetaAction = GetMetaStart | GetMetaResult

export const GET_CUSTOMER_CARD_INFO_START = 'GET_CUSTOMER_CARD_INFO_START'
export const GET_CUSTOMER_CARD_INFO_SUCCESS = 'GET_CUSTOMER_CARD_INFO_SUCCESS'
export const GET_CUSTOMER_CARD_INFO_FAILURE = 'GET_CUSTOMER_CARD_INFO_FAILURE'

export type CustomerCardInfoStart = Action<typeof GET_CUSTOMER_CARD_INFO_START>
export type CustomerCardInfoSuccess = PayloadAction<typeof GET_CUSTOMER_CARD_INFO_SUCCESS, CardInfo>
export type CustomerCardInfoFailure = FailureAction<typeof GET_CUSTOMER_CARD_INFO_FAILURE>

export type CustomerCardInfoResult = CustomerCardInfoFailure | CustomerCardInfoSuccess
export type CustomerCardInfoAction = CustomerCardInfoStart | CustomerCardInfoResult

export const GET_TIPPER_CARD_INFO_START = 'GET_TIPPER_CARD_INFO_START'
export const GET_TIPPER_CARD_INFO_SUCCESS = 'GET_TIPPER_CARD_INFO_SUCCESS'
export const GET_TIPPER_CARD_INFO_FAILURE = 'GET_TIPPER_CARD_INFO_FAILURE'

export type TipperCardInfoStart = Action<typeof GET_TIPPER_CARD_INFO_START>
export type TipperCardInfoSuccess = PayloadAction<typeof GET_TIPPER_CARD_INFO_SUCCESS, CardInfo>
export type TipperCardInfoFailure = FailureAction<typeof GET_TIPPER_CARD_INFO_FAILURE>

export type TipperCardInfoResult = TipperCardInfoFailure | TipperCardInfoSuccess
export type TipperCardInfoAction = TipperCardInfoStart | TipperCardInfoResult

export const SET_DELETE_ACCOUNT_INTENT = 'SET_DELETE_ACCOUNT_INTENT'

export type SetDeleteAccountIntent = PayloadAction<typeof SET_DELETE_ACCOUNT_INTENT, boolean>

export const SET_DELETE_COLLECTOR_ACCOUNT_INTENT = 'SET_DELETE_COLLECTOR_ACCOUNT_INTENT'

export type SetDeleteCollectorAccountIntent = PayloadAction<typeof SET_DELETE_COLLECTOR_ACCOUNT_INTENT, boolean>

export interface DeleteAccountBody {
  message?: Nullable<string>
}
export type DeleteAccountResponse = Response<DeleteAccountBody>

export const DELETE_ACCOUNT_START = 'DELETE_ACCOUNT_START'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE'

export type DeleteAccountStart = Action<typeof DELETE_ACCOUNT_START>
export type DeleteAccountSuccess = Action<typeof DELETE_ACCOUNT_SUCCESS>
export type DeleteAccountFailure = FailureAction<typeof DELETE_ACCOUNT_FAILURE>
export type DeleteAccountResult = DeleteAccountSuccess | DeleteAccountFailure
export type DeleteAccountAction = DeleteAccountStart | DeleteAccountResult

export const CONFIRM_ACCOUNT_DELETED = 'CONFIRM_ACCOUNT_DELETED'

export type ConfirmAccountDeleted = Action<typeof CONFIRM_ACCOUNT_DELETED>

export const SET_VAT_REGISTERED_START = 'SET_VAT_REGISTERED_START'
export const SET_VAT_REGISTERED_SUCCESS = 'SET_VAT_REGISTERED_SUCCESS'
export const SET_VAT_REGISTERED_FAILURE = 'SET_VAT_REGISTERED_FAILURE'

export type SetVatRegisteredStart = Action<typeof SET_VAT_REGISTERED_START>
export type SetVatRegisteredSuccess = Action<typeof SET_VAT_REGISTERED_SUCCESS>
export type SetVatRegisteredFailure = FailureAction<typeof SET_VAT_REGISTERED_FAILURE>

export type SetVatRegisteredResult = SetVatRegisteredFailure | SetVatRegisteredSuccess
export type SetVatRegistered = SetVatRegisteredStart | SetVatRegisteredResult

export const UPDATE_NOTIFICATION_PREFERENCES_START = 'UPDATE_NOTIFICATION_PREFERENCES_START'
export const UPDATE_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_NOTIFICATION_PREFERENCES_SUCCESS'
export const UPDATE_NOTIFICATION_PREFERENCES_FAILURE = 'UPDATE_NOTIFICATION_PREFERENCES_FAILURE'

export type UpdateNotificationPreferencesStart = Action<typeof UPDATE_NOTIFICATION_PREFERENCES_START>
export type UpdateNotificationPreferencesSuccess = PayloadAction<
  typeof UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
  boolean
>
export type UpdateNotificationPreferencesFailure = FailureAction<typeof UPDATE_NOTIFICATION_PREFERENCES_FAILURE>

export type UpdateNotificationPreferencesResult =
  | UpdateNotificationPreferencesSuccess
  | UpdateNotificationPreferencesFailure

export type UpdateNotificationPreferences = UpdateNotificationPreferencesStart | UpdateNotificationPreferencesResult

export const UPDATE_USER_START = 'UPDATE_USER_START'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export type UpdateUserStart = Action<typeof UPDATE_USER_START>
export type UpdateUserSuccess = Action<typeof UPDATE_USER_SUCCESS>
export type UpdateUserFailure = FailureAction<typeof UPDATE_USER_FAILURE>

export type UpdateUserResult = UpdateUserFailure | UpdateUserSuccess
export type UpdateUserAction = UpdateUserStart | UpdateUserResult

export type MetaAction =
  | ConfirmAccountDeleted
  | CustomerCardInfoAction
  | DeleteAccountAction
  | GetMetaAction
  | SetDeleteAccountIntent
  | SetDeleteCollectorAccountIntent
  | SetVatRegistered
  | TipperCardInfoAction
  | UpdateNotificationPreferences
  | UpdateUserAction

export type VerificationStatus = 'approved' | 'pending' | 'deleted'

export interface SupplierStats {
  averageRating: number
  jobCompletions: number
  reliability: number
}

export interface UserInfo {
  reference: string
}

export interface SupplierInfo extends UserInfo {
  receiveMarketingNotifications: boolean
  receiveMarketingNotificationsUrl: string
  selfieUrl: string
  stats: SupplierStats
}

export interface CollectorInfo extends SupplierInfo {
  accountNumber: string
  isVatRegistered: boolean
  licenseInfo: {
    licenseNumber: string
    organisation: string
  }
  operationalCentre: string
  sortCode: string
  vehicleRegNumber: string
  verificationStatus: VerificationStatus

  galleryUrl?: string
  isEndorsed?: boolean
}

export type JunkLoverVerificationStatus = 'active' | 'pending' | 'deleted'

export interface JunkLoverInfo extends SupplierInfo {
  postcode: string
  status: JunkLoverVerificationStatus
}

export interface CustomerInfo extends UserInfo {
  isVatRegistered: boolean
  favouriteCollectorsUrl: string
  preferredPaymentMethodUrl: string

  preferredPaymentMethod?: PaymentMethod
}

interface MessagingInfo {
  hasUnreadMessages: boolean
  hasGlobalUnreadMessages: boolean
  reloadHasUnreadMessagesUrl: string
  reloadHasGlobalUnreadMessagesUrl: string
}

interface FavouritesActionDetails {
  hasUnreadMessages: boolean
  reloadHasUnreadMessages: RemoteUrlAction
}

export interface FavouritesInteractions extends RemoteUrlAction {
  details: FavouritesActionDetails
}

export type FavouriteInteractions = 'listFavouriteCollectorInteractions' | 'listFavouriteUserInteractions'

export type TipperStripeActions = {
  getOrCreateStripeCustomer: RemoteUrlAction
  updateSavedCard: RemoteUrlAction
  viewSavedCard: RemoteUrlAction
}

export type UserActions = {
  addIdentifier: RemoteUrlAction
  listFavouriteCollectorInteractions: FavouritesInteractions
  listFavouriteUserInteractions: FavouritesInteractions
  offerActivities: RemoteUrlAction
  tipperStripeActions: TipperStripeActions
  tradeCustomerPortal: RemoteUrlAction
  updateVatStatus: RemoteUrlAction
  viewCollectorProfile: RemoteUrlAction
  viewCollectorPublicSites: RemoteUrlAction
  viewPublicSites: RemoteUrlAction
  viewTipperVehicles: RemoteUrlAction
  viewTippingHistory: RemoteUrlAction
  viewTippingSiteArrangements: RemoteUrlAction
}

export interface TradeCustomerActions {
  tradeCustomerPortal: RemoteUrlAction
}

export interface AuthIdentifier {
  identifier: AuthIdentifierValue
  type: AuthIdentifierType
  verified: boolean
}

export interface BaseMeta {
  actions: Partial<UserActions>
  capabilities: Capability[]
  creditBalance: Price
  currency: Currency
  deleteUserUrl: string
  email?: string
  firstName: string
  identifiers?: AuthIdentifier[]
  lastName: string
  messagingInfo: MessagingInfo
  phoneNumber?: string
  updateUserUrl: string
  userId: number
}

export type TipperInfo = {
  isTrusted: boolean
  reference: string
  status: VerificationStatus
}

export interface TipperMeta extends BaseMeta {
  tipperInfo: TipperInfo
}

export interface CollectorMeta extends BaseMeta {
  collectorActivitiesUrl: string
  collectorDisposalSitesUrl: string
  collectorInfo: CollectorInfo
  collectorStripeInfo: CollectorStripeInfo
}

export interface JunkLoverMeta extends BaseMeta {
  junkLoverActivitiesUrl: string
  junkLoverInfo: JunkLoverInfo
}

export interface CustomerMeta extends BaseMeta {
  advertCreationUrl: string
  customerActivitiesUrl: string
  customerPaymentCapabilities: PaymentMethod[]
  customerStripeInfo: CustomerStripeInfo
  customerInfo: CustomerInfo
}

export interface TradeCustomerMeta extends CustomerMeta {
  actions: TradeCustomerActions
}

export type Meta =
  | BaseMeta
  | CollectorMeta
  | CustomerMeta
  | JunkLoverMeta
  | TipperMeta
  | (CollectorMeta & CustomerMeta)
  | (CollectorMeta & TipperMeta)
  | (JunkLoverMeta & CustomerMeta)
  | (CollectorMeta & TipperMeta & CustomerMeta)

export interface CollectorStripeInfo {
  accountStatus: string
  lastUpdated: string
  verificationUrl: string
}

export interface CustomerStripeInfo {
  paymentMethodsUrl: string
  stripeCustomerUrl: string
}

export type GetMetaResponse = Response<Meta>

export type CardInfoResponse = {
  body: CardInfo
}

export type CardInfo = {
  brand?: string
  country?: string
  expMonth?: number
  expYear?: number
  last4?: string
  paymentMethodToken: string
}

export type UpdateUserParams = {
  email: string
}

export type SessionRelation = 'associated' | 'owner' | 'public' | 'junklover' | 'collector'
